import React from "react"
import { graphql } from "gatsby"
import PageBuilder from "@/components/pageBuilder"
import Seo from "../components/seo"
import imageUrlBuilder from "@sanity/image-url"
import clientConfig from "../../client-config"
import AniLink from "gatsby-plugin-transition-link/AniLink"
// import Layout from "@/components/layout"

export const query = graphql`
  query MainPageTemplateQuery($id: String!) {
    page: sanityMainPages(id: { eq: $id }, _id: { glob: "!drafts*" }) {
      id
      _id
      ...PageBuilderMainPages
      title
      mainImage {
        asset {
          url
        }
      }
      slug {
        current
      }
      SubPages {
        slug {
          current
        }
        title
        excerpt
        mainImage {
          hotspot {
            x
            y
          }
          asset {
            url
          }
        }
      }
    }
  }
`

const MainPageTemplate = props => {
  const { data } = props
  const page = data && data.page
  const { pageBuilder, _rawPageBuilder } = page

  const builder = imageUrlBuilder(clientConfig.sanity)
  function urlFor(source) {
    return builder.image(source)
  }

  return (
    <div className="bg-white dark:bg-zinc-900">
      <Seo
        title={page.title + " - Budmen"}
        description={page.excerpt}
        defer={false}
        image={urlFor(page.mainImage)
          .width(1200)
          .height(628)
          .auto("format")
          .url()}
        twitterImage={urlFor(page.mainImage)
          .width(1200)
          .height(628)
          .auto("format")
          .url()}
      />
      <PageBuilder
        pageBuilder={pageBuilder}
        _rawPageBuilder={_rawPageBuilder}
      />
      <section className="container mx-auto grid lg:grid-cols-4 md:grid-cols-2 grid-cols-1 gap-4 w-full p-2 py-6">
        {page.SubPages.map(
          (posts, index) =>
            posts.slug != null && (
              <AniLink
                to={"/" + posts.slug.current}
                className="text-zinc-300 shadow-lg dark:text-zinc-300 card-zoom"
                key={index}
                paintDrip
                duration={0.5}
                hex={"#15b8a6"}
              >
                <div
                  style={{
                    backgroundImage:
                      "url(" +
                      urlFor(posts.mainImage).width(800).auto("format").url() +
                      ")",
                  }}
                  className="inline-block card-zoom-image"
                ></div>
                <div className="p-2 bg-black/[0.65] transition-all duration-500 ease-in-out transform absolute w-full h-full flex items-start flex-col justify-center text-white/[0.95]">
                  {posts.category && (
                    <span
                      style={{ background: posts.category.color }}
                      className="rounded-full text-[0.5rem] justify-self-start text-white px-1 mb-2"
                    >
                      {posts.category.title}
                    </span>
                  )}
                  {/* <span className="flex-shrink mr-1 font-bold uppercase tracking-widest text-[0.5rem]">Project</span> */}
                  <h3 className="font-bold uppercase tracking-widest text-lg">
                    {posts.title}
                  </h3>
                  <p className="text-sm">{posts.excerpt}</p>
                </div>
              </AniLink>
            )
        )}
      </section>
    </div>
  )
}

export default MainPageTemplate
